"use client";

import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Users,
  PenTool,
  Code,
  GraduationCap,
  ChevronRight,
} from "lucide-react";

const steps = [
  {
    title: "Consult",
    color: "bg-blue-400",
    icon: Users,
    message:
      "     Our team of seasoned professionals will collaborate closely with you to gain a thorough understanding of your business needs and goals.",
    iconAnimation: {
      y: [0, -5, 0],
      transition: { repeat: Infinity, duration: 1.5, ease: "easeInOut" },
    },
  },
  {
    title: "Design",
    color: "bg-purple-500",
    icon: PenTool,
    message:
      "     Leverage their wealth of experience, our skilled architects will craft bespoke solutions tailored to your unique requirements.",
    iconAnimation: {
      rotate: [-5, 5, -5],
      transition: { repeat: Infinity, duration: 1.5, ease: "easeInOut" },
    },
  },
  {
    title: "Build",
    color: "bg-emerald-400",
    icon: Code,
    message:
      "     Our talented developers and automation engineers will transform your IT vision into reality, constructing robust and scalable solutions.",
    iconAnimation: {
      scale: [1, 1.1, 1],
      transition: { repeat: Infinity, duration: 1.5, ease: "easeInOut" },
    },
  },
  {
    title: "Train",
    color: "bg-orange-400",
    icon: GraduationCap,
    message:
      "     We offer comprehensive training programs that deliver hands-on experience and knowledge transfer, empowering your team to excel.",
    iconAnimation: {
      y: [0, -3, 0, -3, 0],
      transition: { repeat: Infinity, duration: 1.5, ease: "easeInOut" },
    },
  },
];

const TypingEffect = ({ text }) => {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let i = 0;
    const typingInterval = setInterval(() => {
      if (i < text.length) {
        setDisplayedText((prev) => prev + text.charAt(i));
        i++;
      } else {
        clearInterval(typingInterval);
      }
    }, 2000 / text.length); // Adjust timing to complete in about 2.5 seconds

    return () => clearInterval(typingInterval);
  }, [text]);

  return (
    <p className="text-white text-opacity-90 leading-relaxed text-sm md:text-base">
      {displayedText}
    </p>
  );
};

export default function Component() {
  const [activeStep, setActiveStep] = useState(null);

  return (
    <div className="w-full max-w-5xl mx-auto px-4 py-12">
      <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
        Our Approach
      </h2>
      <div className="relative">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {steps.map((step, index) => (
            <motion.div
              key={step.title}
              className={`${step.color} rounded-2xl shadow-lg cursor-pointer overflow-hidden relative`}
              style={{
                aspectRatio: "1",
                transformOrigin: index === 1 || index === 3 ? "right" : "left",
              }}
              initial={false}
              animate={{
                width: activeStep === index ? "140%" : "100%",
                zIndex: activeStep === index ? 20 : 10,
                left:
                  (index === 1 || index === 3) && activeStep === index
                    ? "-40%"
                    : "0%",
              }}
              transition={{ duration: 0.3 }}
              onClick={() => setActiveStep(activeStep === index ? null : index)}
            >
              <div className="absolute inset-0 p-2 flex flex-col items-center justify-center">
                <motion.div animate={step.iconAnimation} className="mb-4">
                  <step.icon className="w-12 h-12 text-white" />
                </motion.div>
                <h3 className="text-xl font-bold text-white text-center">
                  {step.title}
                </h3>
                <AnimatePresence mode="wait">
                  {activeStep === index && (
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.3 }}
                      className="mt-4 text-center max-w-[80%]"
                    >
                      <TypingEffect text={step.message} />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
      <div className="mt-8 flex justify-center items-center space-x-4">
        {steps.map((step, index) => (
          <React.Fragment key={step.title}>
            <motion.button
              className={`w-3 h-3 rounded-full transition-colors ${
                index === activeStep ? step.color : "bg-gray-300"
              }`}
              initial={false}
              animate={{
                scale: index === activeStep ? 1.5 : 1,
              }}
              onClick={() => setActiveStep(index)}
              transition={{ type: "spring", stiffness: 300, damping: 20 }}
            />
            {index < steps.length - 1 && (
              <ChevronRight className="w-4 h-4 text-gray-400" />
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}
