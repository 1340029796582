import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./screens/Home";
import About from "./screens/AboutUs";
import Services from "./screens/Services";
import ContactUs from "./screens/ContactUs";
import DataCenterOperationsPage from "./screens/SubServices/DataCenterOperationsPage";
import PageNotFound from "./screens/PageNotFound";
import JobOpportunity from "./screens/JobOpportunityPage";
import SubServicesModal from "./screens/SubServices/SubServicesModal";
import CustomerSuccessStory from "./screens/SuccessStory/CustomerSuccessStory"
import SaudiLeadingBankSuccess from "./screens/SuccessStory/SaudiLeadingBankSuccess"
import SaudiDigitalLeaderSuccessStory from "./screens/SuccessStory/SaudiDigitalLeaderSuccessStory"
import GlobalTelecomSolutionsProviderSuccessStory from "./screens/SuccessStory/GlobalTelecomSolutionsProviderSuccessStory"
import GlobalEngineeringPowerhouseSuccessStory from "./screens/SuccessStory/GlobalEngineeringPowerhouseSuccessStory"
import IndianCommunicationInnovatorSuccessStory from "./screens/SuccessStory/IndianCommunicationInnovatorSuccessStory"
import AllApplocation from './screens/AllApplication'
import Hiring from "./screens/HiringPage"
import LoginPage from './Login'
import ProtectedRoute from "./ProtectedRoutes";
import AdminDashboard from "./screens/JobsDash/AdminDashboard"
import AdminLogin from "./screens/JobsDash/AdminLogin"
import Testing from './test'
import TimeTracker from './timeTracker'

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/"
            element={
              <TimeTracker pageName="Home">
                <Home />
              </TimeTracker>
            } />
          <Route path="/login" element={<AdminLogin />} />

          {/* time tracker pages */}
          <Route path="/Home"
            element={
              <TimeTracker pageName="Home">
                <Home />
              </TimeTracker>
            } />
          <Route path="/Home/:ServiceNames"
            elementelement={
              <TimeTracker pageName="Home">
                <Home />
              </TimeTracker>
            } />
          <Route path="/About"
            element={
              <TimeTracker pageName="About">
                <About />
              </TimeTracker>
            }
          />

          <Route path="/Services"
            element={
              <TimeTracker pageName="Services">
                <Services />
              </TimeTracker>
            }>
            <Route path="/Services/:ServiceNames"
              element={
                <TimeTracker pageName="Services">
                  <Services />
                </TimeTracker>
              } />
          </Route>

          <Route path="/ContactUs" element={<ContactUs />} />

          <Route path="/ContactUs/:career" element={<ContactUs />} />

          <Route path="/SubServicesModal" element={<SubServicesModal />} />

          <Route path="/CustomerSuccessStory" element={<CustomerSuccessStory />} />

          {/* <Route path="/CustomerSuccessStory/:SuccessStory" element={<CustomerSuccessStory />} /> */}
          <Route path="/SaudiLeadingBankSuccess" element={<SaudiLeadingBankSuccess />} />


          <Route path="/AdminLogin" element={<AdminLogin />} />

          <Route path="/SaudiDigitalLeaderSuccessStory"
            element={
              <TimeTracker pageName="SaudiDigitalLeaderSuccessStory">
                <SaudiDigitalLeaderSuccessStory />
              </TimeTracker>
            }
          />
          <Route path="/GlobalTelecomSolutionsProviderSuccessStory"
            element={
              <TimeTracker pageName="GlobalTelecomSolutionsProviderSuccessStory">
                <GlobalTelecomSolutionsProviderSuccessStory />
              </TimeTracker>
            }
          />
          <Route path="/GlobalEngineeringPowerhouseSuccessStory"
            element={
              <TimeTracker pageName="GlobalEngineeringPowerhouseSuccessStory">
                <GlobalEngineeringPowerhouseSuccessStory />
              </TimeTracker>
            }
          />
          <Route path="/IndianCommunicationInnovatorSuccessStory"
            element={
              <TimeTracker pageName="IndianCommunicationInnovatorSuccessStory">
                <IndianCommunicationInnovatorSuccessStory />
              </TimeTracker>
            }
          />


          <Route path="/Hiring" element={<Hiring />} />
          <Route
            path="/SubServicesModal/:subserviceName"
            element={<SubServicesModal />}
          />
          <Route path="/JobOpportunity" element={<JobOpportunity />} />
          <Route
            path="/JobOpportunity/:jobName"
            element={<JobOpportunity />}
          />


          <Route
            path="/DataCenterOperationsPage"
            element={
              <TimeTracker pageName="DataCenterOperationsPage">
                <DataCenterOperationsPage />
              </TimeTracker>
            }
          />
          <Route path="*" element={<PageNotFound />} />


          {/* protected routes down here ------------------ */}
          <Route
            path="/AllApplications"
            element={
              <ProtectedRoute>
                <AllApplocation />
              </ProtectedRoute>
            }
          />

          <Route
            path="/AdminDashboard"
            // element={<AdminDashboard />} 
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />

          <Route path="/testing" element={<Testing />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
